<script setup lang="ts">
import NestedList from './Components.NestedList.vue';
import { ref, Ref, onMounted, computed, watch } from 'vue';
import { $t } from 'o365-utils';

//import { context } from 'o365-modules';
//import { isGloballyWhitelisted } from '@vue/shared';

const url = new URL(window.location.href);

const props = defineProps<{
    node: NestedListNode
}>();

const emit = defineEmits<{
    (e: 'onClick', val: any): void;
}>();

const showFullscreenButton = ref(false);

const showChildren = ref(props.node.showChildren);
const listItem = ref();
const isActive = ref(false);
const isHighlighted = ref(false);
props.node.showChildrenRef = showChildren;

function traverseParentNodes(node: NestedListNode) {
    if (node.getParent) {
        const parentNode = node.getParent();
        if (parentNode) {
            parentNode.showChildrenRef!.value = true;
            traverseParentNodes(parentNode);
        }
    }
}


onMounted(() => {

    const id = url.searchParams.get('ID');
    const expand = url.searchParams.get('Expand');

    if (id && id == props.node.value || url.searchParams.has('Name') && props.node.name && props.node.name == url.searchParams.get('Name')) {
        showChildren.value = true;
        isActive.value = true;
        traverseParentNodes(props.node);
        //setupEventListeners();
    } else if (expand && parseInt(expand) == props.node.id && !id) {

        showChildren.value = true;
        isActive.value = true;
        isHighlighted.value = true;
        traverseParentNodes(props.node);
        
        //setupEventListeners();
    }

    if (url.search.indexOf('ExpandAll') > 0) {
        showChildren.value = true;
    }

    if (props.node.expandLevel && props.node.level && props.node.expandLevel >= props.node.level) {
        showChildren.value = true;
        traverseParentNodes(props.node);
    }

    props.node.getElement = () => {
            return listItem.value;
        }
  

});

function clickNode() {
    
    if (showChildren.value && props.node.ContentItemId != null && props.node.type == 'Section') return;

    showChildren.value = !showChildren.value;
}

function downloadManual() {
    // @ts-ignore
    //let areaId = ssgAreaInfo.AreaId;
    let areaId = props.node.area;
    let manualId = props.node.manual;
    let url = `/nt/api/content/downloadManual/${areaId}/${manualId}`;

    if (props.node.type == 'Section') {
        url += `/${props.node.id}`;
    }


    window.open(url);

}
function fullscreendManual() {
    let manualId = props.node.manual;
    let url = `/content-item-viewer?Manual_ID=${manualId}`;
    window.open(url,"_blank");
}
</script>

<template>
    <ul class="ps-3">
        <li class="d-flex justify-content-between position-relative menu-item"
        ref="listItem"
            @click="{ clickNode(); emit('onClick', props.node); }"
            :class="{ 'active': isActive, 'menu-highlight': isHighlighted }"
            :data-has-value="node.value != null && node.value != 0" :value="node.value" :contentId="node.ContentItemId"
            :data-manual="node.id" :data-type="node.type">
            <div class="d-flex">
                <i v-if="node.children.length > 0" class="bi bi-chevron-right me-1" :class="{ 'icon-90deg': showChildren }"
                    @click.stop="showChildren = !showChildren;"></i>
                <i v-if="node.children.length == 0" class="bi bi-dash me-1"></i>
                <span class="node-label menu-item" :value="node.value" :contentId="node.ContentItemId">{{ $t(node.label)}}</span>
            </div>
            <button @click.stop="downloadManual" title="Download" class="btn btn-sm btn-link download-btn d-none"
                v-if="!(node.children.length == 0)"><i class="bi bi-download"></i></button>
             <button @click.stop="fullscreendManual" title="fullscreen" class="btn btn-sm btn-link fullscreen-btn d-none"
                v-if="!(node.children.length == 0)"><i class="bi bi-arrows-fullscreen" v-if="showFullscreenButton"></i></button>   

        </li>
        <NestedList :node="childNode" :class="{ 'd-none': !showChildren }" v-for="childNode in node.children"
            @onClick="(val: any) => emit('onClick', val)" />
    </ul>
</template>

<script lang="ts">

export interface NestedListNode {
    id: number;
    ContentItemId: any;
    label: string;
    value: any;
    showChildren: boolean;
    showChildrenRef?: Ref<boolean>;
    children: NestedListNode[];
    getParent?: () => NestedListNode | void;
    name?: string;
    type: string;
    area: number;
    manual: any;
    expandLevel?: number;
    level?: number;
    isDocument: boolean;
    getElement?:() => any;

    
}
</script>

<style scoped>
.download-btn {
    position: absolute;
    right: 0;
    top: -3px;
}

.download-btn:hover {
    background-color: var(--bs-primary);
    color: white;
}
.fullscreen-btn {
    position: absolute;
    right: 20px;
    top: -3px;
}

.fullscreen-btn:hover {
    background-color: var(--bs-primary);
    color: white;
}
ul {
    margin: 0;
    list-style: none;
    user-select: none;
}

ul li {
    padding: 2px;
    display: flex;
    font-size: 1rem;
    color: black !important;
}

ul li:hover {
    cursor: pointer;
    color: var(--bs-primary);
    text-decoration: underline;
}

ul li:hover .d-none {
    display: block !important;
}

ul li:hover[data-has-value=false] {
    text-decoration: none !important;
}

.bi {
    transition: transform 200ms;
    font-size: 0.76rem;
    font-weight: 600;
    align-self: flex-start;
}

.icon-90deg {
    transform: rotate(90deg);
}

.active {
    color: black !important;
    font-weight: 500 !important;
    background-color: #f2f2f2;
    border-radius: 2px !important;
}

[data-bs-theme=dark] .active {
    background-color: #393939 !important;
    color: white;
}

[data-bs-theme=dark] li {
    color: #d3d3d3 !important;
}

.menu-highlight {
    animation-name: blink;
    animation-duration: 2s;
    animation-iteration-count: 1;

}

@keyframes blink {
    0% {
        background-color: #FEFFC0;
        opacity: 1;
    }

    50% {
        background-color: #FEFFC0;
        opacity: .5;
    }

    80% {
        background-color: #FEFFC0;
        opacity: .7;
    }

    100% {
        opacity: 1;
    }
}</style>